// The reason for doing so is to solve "relative assets (images, fonts) fail to find" issue
// The only file changed is bootstrap/_bootstrap.scss

@import "global/variables";
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 1082px,
    xl: 1200px,
    xxl: 1600px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1520px
);
@import "bootstrap/bootstrap";