@import "../../../../../styles/variables.scss";

.lp_draggable_item, .lp_drag_and_drop_list .lp_draggable_item {
	display: grid;
	grid-template-columns: min-content auto;
	grid-column-gap: 12px;
	padding: 12px $spacing;
	cursor: pointer;

	&--drop_area {
		position: relative;
		&::before {
			content: "";
			width: 100%;
			height: 100%;
			border: $border-thickness dashed $light-border-focus;
			position: absolute;
		}
	}

	&__drag_handle {
		grid-column: 1;
		fill: $light-text;
		align-self: center;
	}

	&__content {
		grid-column: 2;
	}
}