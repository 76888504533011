.field-width-xs {
    display: inline-block;
    width: calc(25% - 12px);
    margin-left: 12px;

    &:first-child {
        width: 25%;
        margin-left: 0;
    }
}

.field-width-sm {
    display: inline-block;
    width: calc(40% - 12px);
    margin-left: 12px;

    &:first-child {
        width: 40%;
        margin-left: 0;
    }
}

.field-width-md {
    display: inline-block;
    width: calc(60% - 12px);
    margin-left: 12px;

    &:first-child {
        width: 60%;
        margin-left: 0;
    }
}

.field-width-lg {
    display: inline-block;
    width: calc(75% - 12px);
    margin-left: 12px;

    &:first-child {
        width: 75%;
        margin-left: 0;
    }
}