/*
	DOCUMENT INTRODUCTION
 	Custom button styles based on Design System document.
	Author: Luuk Barten
*/

/*
	NOTES
	BEM style naming conventions used. Source: http://getbem.com/naming/
	Size and colors variables used are defined in variables.scss
*/

/*
	USAGE
	Always add the default button class
 	> Default button: .button

	Additional classes to add
	> Secondary: .button--secondary
	> Sizes and shapes: .button--large | .button--circle | .button--large-circle
	> Borderless: .button--borderless
	> Colored: .button--colored // Uses tier colors
    > Colored Admin: .button--colored-admin // Uses default blue colors
	> Radiobutton: .button--radio // Add this class to a label element wrapping the input element with type = radio
*/

/*
	SUB ELEMENTS
	Optionaly add an icon image in a button
    > Image in button: .button__image
    > Icon in button: .button__icon
*/

.button {
	padding: 0px $spacing;
	height: $button-height;
	line-height: 34px;
	background: none;
	border-radius: 18px;
	border: $border-thickness solid $light-border;
	color: $light-text;
	display: inline-block;

	&:hover {
		border-color: $light-border-focus;
	}

	&:focus {
		background-color: $light-background-focus;
	}

	&:disabled {
		color: $light-border-focus;
		cursor: default;
		background: none;
	}

	&.button--active {
		background-color: $light-background-focus;
	}

	&.button--selected {
		background-color: $dark-background-active;
		border-color: $dark-border-active;
	}

	&.button--large {
		padding: 0px $subsection-spacing;
		border-radius: 24px;
		height: $button-big-height;
		line-height: 46px;
	}

	&.button--circle {
		padding: 0;
		width: $subsection-spacing;
		border-radius: 50%;
	}

	&.button--large-circle {
		padding: 0;
		width: $section-spacing;
		height: $section-spacing;
		border-radius: 50%;
	}

	&.button--borderless {
		border: transparent;
	}

	&.button--colored {
		color: $light-text-contrast;
		@include button-gradient;
		border: transparent;

		&:hover,
		&:focus {
			background: $secondary-color;
		}

		&:disabled {
			background: none;
			color: $dark-text-disabled;
			border: $border-thickness solid $dark-border-disabled;
		}

		&-admin {
			color: $light-text-contrast;
			@include button-gradient--admin;
			border: transparent;

			&:hover,
			&:focus {
				background: $secondary-color--admin;
			}

			&:disabled {
				background: none;
				color: $light-text-disabled;
				border: $border-thickness solid $light-border-disabled;
			}
		}
	}

	&.button--secondary {
		color: $light-text-secondary;

		&:hover {
			color: $light-text;
		}
	}

	&.button--radio {
		line-height: 34px;
		cursor: pointer;

		input {
			display: none;
		}
	}

	&.button--wide {
		text-align: center;
		width: 100%;
	}

	&__image {
		height: 16px;
		margin: 2.5px 0;
		max-width: 16px;
	}

	&__icon {
		height: 16px;
		margin: 0 $button-pair-spacing 0 0;
		max-width: 16px;
	}

	& + .button {
		margin-left: $button-pair-spacing;
	}
}

.button + .button,
.ModalDialog + .button {
	margin-left: $button-pair-spacing;
}

// Tier 0-1 exceptions
.MWQ3YmNjZTUyOWU4MzdmYTEwYmIzZThlZWY0ZDdlN2UyZWIwOWVl,
.MDgzYzdhMmU5YjA5YjJkYjhiMGQ1YTc5YzZmOWY4NTE5NmFjNzdj {
	.button {
		&.button--colored {
			@include button-gradient--inverse;

			&:hover,
			&:focus {
				background: $secondary-color;
			}

			&:disabled {
				background: none;
			}
		}
	}

	.button-blue {
		@include button-gradient--inverse;

		&:disabled {
			background: none;
		}
	}
}
