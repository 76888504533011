@import "../variables.scss";

body {
	font-family: "Roboto", sans-serif;
	font-size: 14px;
	line-height: 1.5;
}

body :lang(zh-Hans) {
	font-family: "Noto Sans SC", "Roboto", sans-serif;
}

h1,
.h1 {
	margin-top: $subsection-spacing;
	margin-bottom: $spacing;

	&:first-child {
		margin-top: 0px;
	}
}

h2,
.h2 {
	margin-top: $subsection-spacing;
}

a:hover {
	text-decoration: none;
}
