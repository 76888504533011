/*Typography Styles*/
.text-strikethrough {
	text-decoration: line-through !important;
}

.desc-list {
	margin-bottom: 0;
	li {
		padding: $jr-card-padding;
		border-bottom: 1px solid $gray-500;
		margin-left: -$jr-card-margin;
		margin-right: -$jr-card-margin;

		&:last-child {
			border-bottom: 0 none;
		}

		& .card-title {
			margin-bottom: 6px;
		}
	}

	&.even-odd {
		li {
			border-bottom: 0 none;

			&:nth-child(2n) {
				background-color: $gray-300;
			}
		}
	}
}
