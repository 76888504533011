@import "../../styles/tl-variables.scss";

// Added element button for higher specifity to trumph generic button styles.

button.tl_button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: min-content;
	height: 44px;
	padding: 0 24px;
	white-space: nowrap;
	border: 0px;
	border-radius: 8px;
	cursor: pointer;
	font-size: $tl-font-size-sixteen;
	font-weight: $tl-font-weight-bold;
	font-family: $tl-font-body;
	color: $tl-color-foundation-11;
	background-color: $tl-color-sunlight-67;
	position: relative;
	overflow: hidden;

	&--full_width {
		width: 100%;
	}

	&:hover::before, &:focus::before {
		content:" ";
		display: block;
		width: 100%;
		height: 100%;
		background-color: $tl-color-on-surface-16;
		position: absolute;
		z-index: 1;
	}

	&:focus, &:active {
		outline: 2px solid $tl-color-foundation-58;
	}

	.tl_button__label, .tl_button__icon {
		z-index: 2;
	}

	.tl_button__icon  {
		margin-left: 8px;
		max-width: 24px;
		filter: $tl-color-foundation-11-filter;
	}


	&:disabled {
		cursor: not-allowed;
		color: $tl-color-on-surface-40;
		background-color: $tl-color-feedback-3;

		&:hover::before, &:focus::before {
			display: none;
		}

		&:focus, &:active {
			outline: none;
		}

		.tl_button__icon {
			@include tl-color-on-surface-40-filter;
		}
	}

	&--large {
		height: 56px;
		font-size: $tl-font-size-twenty;
		line-height: $tl-line-height-body;
	}

	&--secondary {
		background-color: $tl-color-foundation-100;

		&:hover, &:focus {
			background-color: $tl-color-foundation-90;

			&::before {
				display: none;
			}

			&:disabled  {
				background-color: $tl-color-feedback-3;
			}
		}

		&.tl_button--inverse {
			background-color: $tl-color-foundation-11;
			color: $tl-color-foundation-100;

			&:hover, &:focus {
				background-color: $tl-color-foundation-11;
	
				&::before {
					display: block;
				}
	
				&:disabled  {
					background-color: $tl-color-feedback-3;
				}
			}
		}
	}

	&--negative {
		background-color: $tl-color-feedback-2;
		color: $tl-color-foundation-100;

		.tl_button__icon {
			filter: $tl-color-foundation-100-filter;
		}

		&:hover, &:focus {
			background-color: $tl-color-feedback-2;

			&::before {
				display: none;
			}

			&:disabled  {
				background-color: $tl-color-feedback-3;
			}
		}
	}

	&--tertiary, &--icon  {
		color: $tl-color-foundation-100;
		background: none;

		&:hover {
			color: $tl-color-foundation-90;
		}

		&:focus {
			color: $tl-color-foundation-95;
		}

		&:hover, &:disabled {
			background-color: $tl-color-on-surface-4;
		}

		&:disabled {
			color : $tl-color-feedback-3;

			&:hover {
				background: none;
			}
		}

		&:hover::before, &:focus::before {
			display: none;
		}

		&.tl_button--inverse {
			color: $tl-color-foundation-11;

			.tl_button__icon {
				filter: $tl-color-foundation-11-filter;
			}

			&:hover, &:focus {
				color: inherit;

				.tl_button__icon {
					filter: inherit;
				}
			}

			&:hover, &:focus, &:disabled {
				background-color: $tl-color-on-surface-inverse-4;
			}

			&:disabled {
				.tl_button__icon {
					filter: $tl-color-feedback-3-filter;
				}
			}
		}
	}

	&--icon {
		padding: 10px;

		.tl_button__icon {
			filter: $tl-color-foundation-100-filter;
			margin: 0;
		}

		&:hover {
			.tl_button__icon {
				filter: $tl-color-foundation-90-filter;
			}
		}

		&:focus {
			.tl_button__icon {
				filter: $tl-color-foundation-95-filter;
			}
		}

		&:disabled, &:disabled:hover, &:disabled:focus {
			.tl_button__icon {
				filter: $tl-color-feedback-3-filter;
				opacity: 1;
			}
		}
	}
}