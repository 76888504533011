.article {
    font-size: $article-text-size;
    max-width: $max-page-small;
    margin: auto;

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        &:first-child {
            margin-top: 0;
        }
    }

    p {
        margin-bottom: $spacing;
    }

    ul, ol {
        padding-left: 24px;
    }

    a {
        color: $light-text;
        text-decoration: underline;
    }

    &__section-number {
        color: $dark-text-secondary;
        position: absolute;
        right: calc(((100% - 500px) / 2) + 500px);
        padding-right: $button-pair-spacing;
	}

	&__roman_list {
		list-style-type: upper-roman;
	}
}

.dark-theme {

    .article {

        a {
            color: $dark-text;
            text-decoration: underline;
            cursor: pointer;

            &:hover, &:focus {
                color: $dark-text;
                text-decoration: underline;
            }
        }
    }
}