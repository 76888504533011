.social-icon {
	background-repeat: no-repeat;
	width: 14px;
	height: 14px;

	&--teamliquid {
		filter: $filter-liquidplus;
		background-image: url("../../svg/service-teamliquid.svg");
	}

	&--twitch {
		filter: $filter-twitch;
		background-image: url("../../svg/service-twitch.svg");
	}

	&--twitter {
		filter: $filter-twitter;
		background-image: url("../../svg/service-twitter.svg");
	}

	&--reddit {
		filter: $filter-reddit;
		background-image: url("../../svg/service-reddit.svg");
	}

	&--discord {
		filter: $filter-discord;
		background-image: url("../../svg/service-discord.svg");
	}

	&--shopify {
		height: 16px;
		background-image: url("../../svg/service-store.svg");
	}
}
