// Colors
$tl-color-foundation-100: rgba(255, 255, 255, 1);
$tl-color-foundation-95: rgba(227, 241, 255, 1);
$tl-color-foundation-90: rgba(206, 228, 251, 1);
$tl-color-foundation-76: rgba(149, 190, 241, 1);
$tl-color-foundation-58: rgba(70, 137, 224, 1);
$tl-color-foundation-28: rgba(17, 63, 127, 1);
$tl-color-foundation-15: rgba(2, 30, 74, 1);
$tl-color-foundation-11: rgba(0, 21, 56, 1);
$tl-color-foundation-8: rgba(0, 15, 41, 1);

$tl-color-feedback-1: rgba(70, 224, 122, 1);
$tl-color-feedback-2: rgba(224, 70, 88, 1);
$tl-color-feedback-3: rgba(103, 108, 117, 1);

$tl-color-sunlight-95: rgba(255, 249, 227, 1);
$tl-color-sunlight-91: rgba(254, 243, 209, 1);
$tl-color-sunlight-80: rgba(250, 226, 159, 1);
$tl-color-sunlight-67: rgba(247, 201, 94, 1);
$tl-color-sunlight-55: rgba(240, 159, 38, 1);
$tl-color-sunlight-28: rgba(134, 66, 9, 1);
$tl-color-sunlight-15: rgba(76, 11, 1, 1);
$tl-color-sunlight-11: rgba(56, 0, 0, 1);

$tl-color-cyan-95: rgba(227, 250, 255, 1);
$tl-color-cyan-89: rgba(204, 241, 250, 1);
$tl-color-cyan-74: rgba(142, 214, 235, 1);
$tl-color-cyan-53: rgb(58, 176, 212);
$tl-color-cyan-27: rgba(14, 95, 122, 1);
$tl-color-cyan-15: rgba(2, 52, 72, 1);
$tl-color-cyan-11: rgba(0, 39, 56, 1);

$tl-color-purple-95: rgba(227, 228, 255, 1);
$tl-color-purple-90: rgba(208, 209, 253, 1);
$tl-color-purple-79: rgba(158, 156, 247, 1);
$tl-color-purple-62: rgba(86, 81, 237, 1);
$tl-color-purple-30: rgba(24, 19, 132, 1);
$tl-color-purple-15: rgba(9, 3, 75, 1);
$tl-color-purple-11: rgba(6, 0, 56, 1);

$tl-color-on-surface-4: rgba(255, 255, 255, 0.04);
$tl-color-on-surface-8: rgba(255, 255, 255, 0.08);
$tl-color-on-surface-16: rgba(255, 255, 255, 0.16);
$tl-color-on-surface-40: rgba(255, 255, 255, 0.4);

$tl-color-on-surface-inverse-4: rgba(0, 0, 0, 0.04);
$tl-color-on-surface-inverse-8: rgba(0, 0, 0, 0.08);
$tl-color-on-surface-inverse-16: rgba(0, 0, 0, 0.16);
$tl-color-on-surface-inverse-40: rgba(0, 0, 0, 0.4);

$tl-bg-linear-gradient-1: linear-gradient(0deg, #001538 50.75%, #021e4a 79.08%, #113f7f 100%);

// Color filters for overwirting SVG icon colors
$tl-color-foundation-100-filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
	hue-rotate(339deg) brightness(100%) contrast(104%);
$tl-color-foundation-95-filter: brightness(0) saturate(100%) invert(95%) sepia(68%) saturate(7497%)
	hue-rotate(178deg) brightness(106%) contrast(104%);
$tl-color-foundation-90-filter: brightness(0) saturate(100%) invert(96%) sepia(20%) saturate(5412%)
	hue-rotate(176deg) brightness(104%) contrast(97%);
$tl-color-foundation-76-filter: brightness(0) saturate(100%) invert(73%) sepia(73%) saturate(594%)
	hue-rotate(181deg) brightness(100%) contrast(90%);
$tl-color-foundation-58-filter: brightness(0) saturate(100%) invert(46%) sepia(98%) saturate(404%)
	hue-rotate(176deg) brightness(92%) contrast(90%);
$tl-color-foundation-11-filter: brightness(0) saturate(100%) invert(9%) sepia(15%) saturate(7118%)
	hue-rotate(196deg) brightness(100%) contrast(108%);
$tl-color-feedback-1-filter: brightness(0) saturate(100%) invert(76%) sepia(34%) saturate(857%)
	hue-rotate(81deg) brightness(97%) contrast(84%);
$tl-color-feedback-2-filter: brightness(0) saturate(100%) invert(46%) sepia(33%) saturate(3672%)
	hue-rotate(322deg) brightness(87%) contrast(103%);
$tl-color-feedback-3-filter: brightness(0) saturate(100%) invert(42%) sepia(6%) saturate(647%)
	hue-rotate(180deg) brightness(95%) contrast(82%);
$tl-color-cyan-53-filter: brightness(0) saturate(100%) invert(73%) sepia(11%) saturate(2498%)
	hue-rotate(157deg) brightness(82%) contrast(103%);
$tl-color-sunlight-11-filter: brightness(0) saturate(100%) invert(6%) sepia(58%) saturate(4279%)
	hue-rotate(358deg) brightness(97%) contrast(111%);

@mixin tl-color-on-surface-40-filter {
	filter: $tl-color-foundation-100-filter;
	opacity: 0.4;
}

// Font families
$tl-font-body: "DM Sans", sans-serif;
$tl-font-title: "DM Sans", sans-serif;
$tl-font-display: kaneda-gothic, sans-serif;

// Font sizes
$tl-font-size-twelve: 12.8px;
$tl-font-size-sixteen: 16px;
$tl-font-size-twenty: 20px;
$tl-font-size-twenty-five: 25px;
$tl-font-size-thirty-one: 31.25px;
$tl-font-size-thirty-nine: 39.06px;
$tl-font-size-forty-eight: 48.03px;
$tl-font-size-sixty-one: 61.04px;
$tl-font-size-seventy-six: 76.29px;

// Font Weights
$tl-font-weight-regular: 400;
$tl-font-weight-bold: 700;

// Line Heights
$tl-line-height-body: 150%;
$tl-line-height-title: 125%;
$tl-line-height-display: 125%;

// Breakpoints
$tl-viewport-md: 768px;
$tl-viewport-lg: 1024px;
$tl-viewport-xl: 1400px;

// mixins
@mixin tl-container {
	margin: 0 8px;
	padding: 0 8px;

	@media screen and (min-width: $tl-viewport-md) {
		margin: 0 16px;
		padding: 0 12px;
	}

	@media screen and (min-width: $tl-viewport-lg) {
		margin: 0 68px;
	}

	@media screen and (min-width: $tl-viewport-xl) {
		margin: 0 auto;
		max-width: 1248px;
	}
}

@mixin tl-grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: 16px;

	@media screen and (min-width: $tl-viewport-md) {
		grid-template-columns: repeat(8, 1fr);
		grid-column-gap: 24px;
	}

	@media screen and (min-width: $tl-viewport-lg) {
		grid-template-columns: repeat(12, 1fr);
	}

	@media screen and (min-width: $tl-viewport-xl) {
		max-width: 1248px;
	}
}
