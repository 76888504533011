@import "../../../../../styles/variables.scss";

.lp_draggable_item , .lp_drag_and_drop_list .lp_draggable_item {
	&--drop_area {
		&::before {
			border: $border-thickness dashed $light-border-focus;
		}
	}

	&__drag_handle {
		fill: $light-text;
	}

	&:hover {
		background-color: $light-background-hover;
	}

	&:focus, &--selected {
		background-color: $light-background-focus;

		&:hover {
			background-color: $light-background-focus;
		}
	}
}