@import "../../../styles/variables.scss";

.--theme-dark {
	.lp_block{
		border-color: $dark-border;

		@media only screen and (min-width: 430px) {
			@include transparent-bg-with-glowbar;

			&--clickable:hover {
				background-color: $dark-background-hover;
			}
		}

		&+.lp_block {
			&::before,
			&::after {
				display: none;
			}
		}
	}
}