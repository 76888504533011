@import "variables.scss";

.light-theme {
    background-color: $light-background;

    // font styling:
    color: $light-text;

    .secondary {
        font-size: $secondary-text-size;
        color: $light-text-secondary;
    }

    button {
        height: $button-height;
        padding: $button-padding;
        border-radius: $button-border-radius;
        border: $border-thickness solid $light-border;
        background: none;
        color: $light-text;
        font-size: $primary-text-size;

        &:hover {
            background: $light-background-hover;
        }

        &:focus, &:active {
            background: $light-background-focus;
        }

        &:hover {
            cursor: pointer;
        }

        &:disabled {
            cursor: default;
			background: none;
			color: $light-text-disabled;

			&:hover {
				border-color: $light-border;
			}
        }
    }

    .button-secondary {
        color: $light-text-secondary;
    }

    .button-blue {
        @include button-gradient--admin;
        border: none;
        padding: $button-no-border-padding;
        color: $dark-text;

         &:hover, &:focus, &:active {
            background: $secondary-color--admin;
        }

        &:hover {
            cursor: pointer;
        }

        &:disabled {
            cursor: default;
            background-color: $light-background-disabled;
            border: $border-thickness solid $light-border;
            color: $light-border-focus;
            padding: $button-padding;
        }
    }

    .button-big {
        height: $button-big-height;
        padding: $button-big-padding;
        border-radius: $button-border-radius;
    }

    .button-big.button-blue {
        padding: $button-big-no-border-padding;
    }

    .CheckBox {

        input[type="checkbox"] {

            &:checked ~ label .box {
                @include button-gradient--admin;

            }
        }
    }

    .TextField, .TextareaField, .SelectField {
        &:hover, &:focus, &:active {
            .label {
                color: $light-text;
            }

            .field {
                border-color: $light-border-focus;
            }
        }

        .label {
            position: relative;
            left: 1px;
            font-size: $secondary-text-size;
            color: $light-text-secondary;
        }

        .focus {
            color: $light-text;
        }

        .field {
            margin: 0;
            width: 100%;
            border-radius: $border-radius;
            background: none;
            border: $border-thickness solid $light-border;
            color: $light-text;
            padding: 5px 10px;
            resize: none;

            &:focus, &:hover, &:active {
                outline: none;
                border-color: $light-border-focus;
                color: $light-text;
            }

            &:focus-within {
                box-shadow: none;
                border-color: $light-border-focus;

                .label {
                    color: $light-text;
                }
            }

            &::placeholder {
                font-size: $primary-text-size !important;
            }
        }
    }

    .SelectField {
        .react-dropdown-select {
            height: 35px !important;
            caret-color: $light-text;
        }

        .react-dropdown-select-input {
            color: $light-text;
            font-size: inherit;
            margin: 0;
        }

        .react-dropdown-select-dropdown {
            top: 35px;
            left: -2px;
            background: $light-background;
            border: $border-thickness solid $light-border-focus;
            border-radius: $border-radius;
            color: $light-text;
        }

        .react-dropdown-select-item {
            border-bottom: none;

            &:hover {
                background-color: $light-background-hover;
            }
        }

        .react-dropdown-select-item-selected {
            border-bottom: none !important;
            background-color: $light-background-focus !important;
            color: $light-text;
        }

        .react-dropdown-select-dropdown-handle {
            margin-bottom: 0px;
        }

        svg {
            align-content: center;
            filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(220deg) brightness(108%) contrast(107%) opacity(20%);
        }

        path {
            d: path("M 5 35 H 35 L 20 17.5");
        }

        input[type=number]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            appearance: none;
        }
    }

    .modal-content {
        background-color: $light-background;
        border: $border-thickness solid $light-border;
    }

    &.preferred-games-modal {

        .game-button{
            img {
                filter:none;
            }

            &.selected {
                @include button-gradient--admin;

                &:hover {
                    background: $secondary-color--admin;
                }

                &:focus {
                    @include button-gradient--admin;
                }

                img {
                    filter:$filter-white;
                }
            }
        }
    }
}