@import "../../styles/variables.scss";

.RadioField {
    position: relative;
    margin: 18px 0;

    &:first-child {
        margin-top: $spacing;
    }

    &:last-child {
        margin-bottom: $spacing;
    }

    input[type="radio"] {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;

        &:checked {

            &~ label .RadioField__radio {
                &:after {
                    display: block;
                }
            }
        }
    }

    &__label {
        padding-left: 26px;
        cursor: pointer;
        position: relative;
    }

    &__radio {
        display: block;
        position: absolute;
        top: 1.5px;
        left: 0px;
        height: 18px;
        width: 18px;
        border-radius: 9px;
        outline: none;

        &:after {
            content: "";
            @include button-gradient;
            position: absolute;
            display: none;
            width: 14px;
            height: 14px;
            border-radius: 7px;
            top: 1px;
            left: 1px;
        }
    }
}