/*Avatar Styles*/
.user-avatar {
	margin-right: 18px;

	& img {
		max-width: 100%;
		height: auto;
	}
}

.user-detail {
	& .user-name {
		margin-bottom: 2px;
		font-weight: 400;
	}
	& .user-description {
		font-size: 13px;
		margin-bottom: 0;
		color: $gray-600;
	}
}
