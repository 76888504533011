// global color, CSS variables set per tier
$primary-color: var(--primary-color);
$secondary-color: var(--secondary-color);
$active-tab-color: var(--active-tab-color);
$main-bg-color: var(--main-bg-color);
$transparent-bg-color: var(--transparent-bg-color);
$transparent-dialog-color: var(--transparent-dialog-color);
$primary-color--admin: rgb(0, 123, 199);
$secondary-color--admin: rgb(0, 157, 254);
$warning: rgb(255, 136, 0);
$warning-transparent: rgba(253, 244, 237, 1);
$error: rgb(238, 5, 63);
$confirm-ok: rgb(0, 162, 99);
$confirm-ok-transparent: rgba(238, 251, 244, 1);
$gray-transparent: rgba(16, 16, 16, 0.09);

// tier colors
$tier-color-a-primary: rgba(255, 255, 255, 1);
$tier-color-a-secondary: rgba(16, 16, 16, 0.15);
$tier-color-b-primary: rgba(0, 162, 99, 1);
$tier-color-b-secondary: rgba(255, 255, 255, 1);
$tier-color-c-primary: rgba(0, 123, 199, 1);
$tier-color-c-secondary: rgba(0, 162, 99, 1);
$tier-color-d-primary: rgba(193, 0, 172, 1);
$tier-color-d-secondary: rgba(0, 123, 199, 1);
$tier-color-e-primary: rgba(244, 75, 13, 1);
$tier-color-e-secondary: rgba(193, 0, 172, 1);
$tier-color-f-primary: rgba(195, 186, 62, 1);
$tier-color-f-secondary: rgba(244, 75, 13, 1);
$tier-color-g-primary: rgba(3, 20, 34, 1);
$tier-color-g-secondary: rgba(195, 186, 62, 1);

// social colors:
$discord: #5865f2;
$facebook: #1877f2;
$facebook-hover: #2c88ff;
$google: #4285f4;
$google-hover: #5998ff;
$reddit: #ff4500;
$twitch: #9146ff;
$twitch-hover: #a465ff;
$twitter: #38a1f3;
$store: rgb(255, 255, 255);
$arp: #282829;
$riot: rgb(209, 54, 58);
$epic-games: rgb(255, 255, 255);

// (default) light-theme colors:
$light-background: rgb(255, 255, 255);
$light-background-hover: rgba(16, 16, 16, 0.09);
$light-background-focus: rgba(16, 16, 16, 0.15);
$light-background-active: rgba(16, 16, 16, 0.15);
$light-background-disabled: rgba(16, 16, 16, 0.09);
$light-background-placeholder: rgba(16, 16, 16, 0.15);
$light-text: rgb(16, 16, 16);
$light-text-secondary: rgba(16, 16, 16, 0.56);
$light-text-disabled: rgba(16, 16, 16, 0.43);
$light-text-contrast: rgba(255, 255, 255, 1);
$light-border: rgba(16, 16, 16, 0.43);
$light-border-hover: rgba(16, 16, 16, 0.56);
$light-border-focus: rgba(16, 16, 16, 1);
$light-border-disabled: rgba(16, 16, 16, 0.43);
$table-border: rgba(16, 16, 16, 0.43);

// dark-theme colors:
$team-liquid-blue: rgb(3, 20, 34);
$dark-background: rgba(16, 16, 16, 0.94);
$dark-background-hover: rgba(255, 255, 255, 0.06);
$dark-background-focus: rgba(255, 255, 255, 0.13);
$dark-background-active: rgba(255, 255, 255, 0.13);
$dark-text: rgb(255, 255, 255);
$dark-text-secondary: $dark-text;
$dark-text-disabled: rgba(255, 255, 255, 0.32);
$dark-border: rgba(255, 255, 255, 0.32);
$dark-border-hover: rgba(255, 255, 255, 0.44);
$dark-border-focus: rgb(255, 255, 255);
$dark-border-disabled: rgba(255, 255, 255, 0.32);
$dark-border-active: $dark-border-disabled;
$dark-modal-background-color: $transparent-dialog-color;

// filters:
$filter-white: invert(98%) sepia(9%) saturate(113%) hue-rotate(246deg)
	brightness(117%) contrast(100%);
$filter-dark-text: invert(98%) sepia(9%) saturate(113%) hue-rotate(246deg)
	brightness(117%) contrast(100%);
$filter-dark-text-secondary: invert(98%) sepia(9%) saturate(113%)
	hue-rotate(246deg) brightness(117%) contrast(100%) opacity(44%);
$filter-dark-background-image: invert(98%) sepia(9%) saturate(113%)
	hue-rotate(246deg) brightness(117%) contrast(100%) opacity(6%);
$filter-dark-banner-background-image: invert(98%) sepia(9%) saturate(113%)
	hue-rotate(246deg) brightness(117%) contrast(100%) opacity(20%);
$filter-dark-dropdown-arrow-color: invert(0%) sepia(0%) saturate(0%)
	hue-rotate(220deg) brightness(108%) contrast(107%) opacity(20%);
$filter-warning: invert(68%) sepia(43%) saturate(7101%) hue-rotate(3deg)
	brightness(107%) contrast(103%);
$filter-error: invert(16%) sepia(98%) saturate(6709%) hue-rotate(340deg)
	brightness(93%) contrast(100%);
$filter-confirm-ok: invert(46%) sepia(61%) saturate(6903%) hue-rotate(143deg)
	brightness(101%) contrast(101%);
$filter-liquidplus: invert(29%) sepia(96%) saturate(1107%) hue-rotate(177deg)
	brightness(103%) contrast(103%);
$filter-twitch: invert(29%) sepia(27%) saturate(2472%) hue-rotate(231deg)
	brightness(91%) contrast(91%);
$filter-twitter: invert(51%) sepia(69%) saturate(497%) hue-rotate(163deg)
	brightness(98%) contrast(98%);
$filter-reddit: invert(33%) sepia(26%) saturate(7500%) hue-rotate(1deg)
	brightness(103%) contrast(104%);
$filter-discord: invert(34%) sepia(78%) saturate(2663%) hue-rotate(224deg)
	brightness(100%) contrast(90%);
$filter-riot: invert(29%) sepia(78%) saturate(1675%) hue-rotate(333deg)
	brightness(85%) contrast(95%);
$filter-epic-games: invert(98%) sepia(9%) saturate(113%) hue-rotate(246deg)
	brightness(117%) contrast(100%);
$filter-gray: invert(66%) sepia(0%) saturate(6231%) hue-rotate(61deg)
	brightness(105%) contrast(91%);
$filter-dark-gray: invert(66%) sepia(0%) saturate(6231%) hue-rotate(61deg)
	brightness(75%) contrast(91%);
$filter-black-white: saturate(0%);

//font-families
$heading-font: kaneda-gothic, sans-serif;
$system-font: Roboto, sans-serif;

// font sizes:
$article-text-size: 16px;
$primary-text-size: 14px;
$secondary-text-size: 12px;
$rare-large: 120px;
$rare-medium: 96px;
$rare-small: 72px;
$h1-size: 48px;
$h2-size: 36px;
$h3-size: 24px;

// fixed-widths:
$modal-width: 408px;
$modal-width-mobile: 388px;
$max-content-width: 1660px;

// borders:
$border-radius: 4px;
$border-thickness: 1px;

// padding/spacing:
$mobile-spacing: 16px;
$spacing: 24px;
$subsection-spacing: 36px;
$section-spacing: 60px;
$dropdown-padding: 12px 0;
$dropdown-item-padding: 7.5px 24px;
$input-dropdown-item-padding: 0 14px;
$button-spacing: 12px;
$text-line-spacing: 12px;
$button-pair-spacing: 8px;

// buttons
$button-height: 36px;
$button-padding: 0 24px;
$button-no-border-padding: 0 25px;
$button-border-radius: 50px;
$button-big-height: 48px;
$button-big-padding: 0 36px;
$button-big-no-border-padding: 0 37px;

// responsive breakpoints
$tablet-portrait-up: 600px;
$tablet-landscape-up: 900px;
$mobile-cutoff: 1024px;
$desktop-up: 1200px;
$big-desktop-up: 2000px;

// max page widths
$max-page-small: 500px;
$max-page-article: 750px;
$max-page-medium: 960px;
$max-page-full-width: 1440px;

// max heighths
$modal-height-limit: 612px;

// mixins:
@mixin border-dark {
	border-radius: $border-radius;
	border-collapse: collapse;
	border-style: hidden;
	box-shadow: 0 0 0 $border-thickness $dark-border;
}

@mixin transparent-bg-with-glowbar {
	background-color: $transparent-bg-color;
	position: relative;

	&::before,
	&::after {
		content: "";
		position: absolute;
		left: 18px;
		top: -1px;
		height: 1px;
		width: 90px;
		background: none;
		background: $secondary-color;
	}

	&::after {
		top: -2px;
		height: 3px;
		filter: blur(12px);
	}
}

@mixin button-gradient {
	background: $secondary-color;
}

@mixin button-gradient--inverse {
	background: $secondary-color;
}

@mixin button-gradient--admin {
	background: $primary-color--admin;
}

@mixin bar-gradient {
	background: $secondary-color;
}

@mixin progress {
	background: $primary-color;
}