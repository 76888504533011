/*
Author         : G-axon
Template Name  : Jumbo React - Admin Template
Version        : 1.0
*/
/*=============================================================
    Table of Contents:
    =============================
    A. Globals
        01. Custom Animation
    B. Base
        01. Base Styles
        02. Typography Styles
    C. Layouts
        01. App Layout Styles
        02. Sidebar Styles
        03. Header Styles
        04. Main Content Styles
        05. Footer Styles
        06. Right Sidebar Styles
    D. UI Components
        01. Alert Styles
        02. Avatar Styles
        03. Badges Styles
        04. Blockquotes Styles
        05. Bottom Navigation Styles
        06. Breadcrumb Styles
        07. Button Styles
        08. Button Group Styles
        09. Callout Styles
        10. Cards Styles
        11. Charts Styles
        12. Chips Styles
        13. Carousel Styles
        14. Color Picker Styles
        15. Drawer Styles
        16. E-commerce Styles
        17. Grids List Styles
        18. Icons Styles
        19. Label Styles
        20. List Styles
        21. Nav Style
        22. Pricing Styles
        23. Progressbar Styles
        24. Popover Styles
        25. React Joyride Styles
        26. Tables Styles
        27. Tabs Styles
        28. Testimonial Styles
        29. Time Lines Style
        30. Joyride Tour Styles
        31. Form Styles
    E. Pages
        01. Login Styles
        02. Error Styles
        03. Editor Styles
        04. Contact Page Styles
        05. Portfolio Styles
        06. Faqs Styles
        07. Dashboard Styles
    F. Slider
    G. Modules
        01. App Module Style
        02. Chat Module Styles
        03. Mails Module Styles
        04. Contact Module Styles
        05. Calendar Module Style
  =============================================================*/
//Custom Variables
@import "global/_global-dir";
//Custom Files
@import "base/_base-dir";
@import "layout/_layout-dir";
@import "ui/_ui-dir";
@import "pages/_pages-dir";
@import "slider/owl-carousel";
@import "module/_module-dir";
@import "variables.scss";
@import "liquidplus/admin-components.scss";
@import "liquidplus/articles.scss";
@import "liquidplus/buttons.scss";
@import "liquidplus/common-icons.scss";
@import "liquidplus/input-fields.scss";
@import "liquidplus/typography.scss";
@import "dark-theme.scss";
@import "light-theme.scss";
@import "tl-variables.scss";

html {
	--main-bg-color: #{$tl-color-foundation-11};
	--transparent-bg-color: rgba(16, 16, 16, 0.67);
	--transparent-dialog-color: rgba(16, 16, 16, 0.94);
	--primary-color: #{$tl-color-cyan-53};
	--secondary-color: #{$tl-color-foundation-58};
	--active-tab-color: #{$tl-color-foundation-58};
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-o-font-smoothing: antialiased;
}

body {
    background-color: $main-bg-color;
}

.app-container {
	min-height: 100vh;
    position: relative;
}

.--with_gradient::before {
    content: '';
    width: 100%;
    height: 24vh;
    top: 0;
    position: absolute;
    background: linear-gradient(
        0deg,
        $tl-color-foundation-11 9.75%,
        $tl-color-foundation-15 52.08%,
        $tl-color-foundation-28 100%
    );
}

.app-main {
    width: 100%;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    display: block;
    margin: 0;


    .background {
        background-image: url(../svg/background_layover.svg);
        background-color: $team-liquid-blue;
        background-position: left top;
        background-repeat: no-repeat;
        background-size: 100%;
        min-height: 100vh;
        height: 100%;

        section, .section {
            min-height: calc(100vh - 173px);
            width: 100%;
            color: white;

            table {
                border-radius: 5px;
                border-collapse: collapse;
                border-style: hidden;
                box-shadow: 0 0 0 2px $dark-border;

                // Microsoft Edge Only:
                @supports (-ms-ime-align: auto) {
                    border: 2px solid $dark-border;
                    border-radius: 5px;
                }

                tr {
                    padding-left: 12px;
                    padding-right: 12px;
                }

                th, td {
                    border-top: 2px solid $dark-border;
                }

                th {
                    padding-top: 15px;
                    padding-bottom: 15px;
                    font-weight: 400;
                    font-size: 12px;
                    color: rgba(255, 255, 255, 0.5);
                }
            }
        }

        button {
            border-radius: 17px;
            border: none;
            border: 2px solid $dark-border;
            background: none;
            color: rgba(255, 255, 255, 0.5);
            padding: 5px 20px;

            &:hover {
                background: $dark-background-hover;
            }

            &:focus, &:active {
                background: $dark-background-focus;
            }

            &:hover {
                cursor: pointer;
            }

            &:disabled {
                cursor: default;
                background: none;
            }
        }

        .button-blue {
            background: $primary-color;
            border: 2px solid $primary-color;
            color: white;

            &:hover, &:focus, &:active {
                background: white;
                color: $primary-color;
                font-weight: 500;
                border-color: white;
            }

            &:hover {
                cursor: pointer;
            }

            &:disabled {
                cursor: default;
                background: none;
                border-color: $dark-border;
                color: rgba(255, 255, 255, 0.5);
                font-weight: 400;
            }
        }

        .button-big {
            padding: 10px 30px;
            border-radius: 50px;
        }
    }
}

.shrink-wrap {
    width: 1px;
}