/*
	DOCUMENT INTRODUCTION
 	Custom admin page component styles based on Design System document.
	Author: Luuk Barten
*/

/*
	NOTES
	BEM style naming conventions used. Source: http://getbem.com/naming/
	Size and colors variables used are defined in variables.scss
*/

/*
	EXAMPLE
	The styles in this document have been applied to the Quest administration screens
	since these contain a lot of the admin screen components used on all the other pages.
	The QuestCreate and QuestView pages have most of the form components.
*/

/*
	USAGE
	Make sure to add the "admin-screen" class to a higher level element of the page to ensure
	you can easily override the specificity of the autogenerated Jumbo material components.

	Styles are applied by adding the specific classes to the material elements. See the Quest pages
	for examples on the usage
*/

/*
	CLASSES
 	> .admin						// Add to a top level element of the admin page to easily override specificit.
	> .admin--dark					// Variant to ensure contrast use of colors. Add this when placing the form fields on a dark background.
	> .tab							// Add to the Tab component within Tabs.
	> .pagination					// Add to the TablePagination component.
	> .pagination__select			// For the select element in the TablePagination component.

	> .data-table					// Add to the Table component.
	> .data-table__note				// Small note after the table.
    > .data-table__legend			// Legend at the bottom of the table.
    > .data-table__legend-icon		// Icon that is explaing in the legend. "[ICON] Description of icon"
	> .data-table__header			// Add to the TableHead > TableCell component.
	> .data-table__data-cell		// Add to the TableBody > TableCell component.
	> .data-table__data-cell--main	// Data cell variant that is use for main content at the start of the page.
	> .data-table__data-cell--long	// Data cell variant that grows in height and needs to align its content at the top of the cell.
	> .data-table__multi-line		// Used to display multiple elements of a date-cell in a grid, vertically aligned
	> .data-table__inline-image		// Small image within a multi-line display
    > .data-table__trailing-icon	// Image element in its own TableCell component to show the status of a named row data object, usually added as the content of a second column.
	> .data-table__item-title		// Main title of listed table date
	> .data-table__item-sub-title	// Sub title of listed table date.

	> .form-field					// Row in a form containing an intro, label, input (text, select, or radio)
	> .form-field--dual				// Additional class: Row with 2 columns (used for start and end date input configuration for example)
	> .form-field--wide				// Additional class: Wider version for longer intro
	> .form-field__part				// One of the 2 columns within the dual version
	> .form-options					// Last row to hold cancel and submit buttons
	> .form-field__intro			// Introduction text explaining what the input is needed
	> .form-field__label			// Label for input field (needs to be a label element with a "for" attribute connected to the "id" of the field.
	> .form-field__input			// Simple text input field
	> .form-field__multi-input		// Input for multiple options selected from a list.
	> .form-field__textarea			// Simple text area field
	> .form-field__select			// Custom select drop down
	> .form-field__select-selected	// Selected value shown in closed state
	> .form-field__select-options	// Options to choose from (hidden till the user clicks on the selected value)
	> .form-field__select-option	// Single option in the options list
	> .form-field__checkbox-container	// Wrapper for checkbox elements. This should be a "label" element wrapping around an input type = checkbox element. This ensures we can click the label to check the checkbox. It also ensures we can render our own checkbox icons.
	> .form-field__checkbox-container--checked	// Version with a "checked" icon.
	> .form-field__checkbox			//Input checkbox within the containing wrapper
*/

.admin {
	position: relative;

	.options-bar {
		position: absolute;
		padding: $spacing;
		top: 0px;
		right: 0px;
		height: 84px;
		width: 100%;

		.option {
			display: inline-block;

			&--right {
				float: right;
				margin-left: $button-spacing;
			}
		}

		> :last-child {
			float: right;
		}

		> .options-back:first-child {
			float: left;
		}
	}

	&__content {
		padding: 48px 36px;
	}

	.tab {
		text-transform: none;
	}

	.nested-list-item {
		list-style: none;
	}

	.pagination {
		float: right;
		padding-right: 0;

		&__select {
			min-width: 30px;
		}
	}

	.data-table {
		width: 100%;
		border-radius: $border-radius;
		border: $border-thickness solid $table-border;
		// https://stackoverflow.com/questions/628301/css3s-border-radius-property-and-border-collapsecollapse-dont-mix-how-can-i
		border-spacing: 0;
		border-collapse: separate;

		&__note {
			padding-left: $spacing;
			color: $light-text-secondary;
			font-size: $secondary-text-size;
			line-height: 2.5;
		}

		&__legend {
			display: block;
			padding-left: 0px;
			margin-top: $spacing;

			&-icon {
				height: 14px;
				margin-right: $button-pair-spacing;
			}
		}

		&__header {
			height: 48px;
			color: $light-text;
			font-size: $secondary-text-size;
			font-weight: normal;
			padding: 0 $spacing;
			border-bottom: $border-thickness solid $table-border;
		}

		&__data-cell {
			position: relative;
			padding: 16px $spacing;
			height: 68px;
			border-bottom: 1px solid $table-border;
			vertical-align: middle;
			display: table-cell;
			font-size: $primary-text-size;

			&:last-child {
				text-align: right;
				padding-right: $spacing;

				.lp_dropdown {
					margin: 0 0 0 auto;
				}
			}
		}

		&__data-cell--main {
			height: 108px;
		}

		&__data-cell--long {
			vertical-align: top;
			line-height: 36px;
			height: auto;
		}

		&__multi-line {
			display: grid;
			grid-template-columns: 40px auto;
			grid-column-gap: 18px;
			align-items: center;
		}

		&__multi-line--widegap {
			grid-column-gap: 18px;
		}

		&__multi-line--large-icon {
			grid-template-columns: 60px auto;

			.data-table__inline-image {
				width: 60px;
				height: 60px;
			}
		}

		&__multi-line--no-icon {
			grid-template-columns: auto;
		}

		&__inline-image {
			margin-bottom: 0;
			width: 40px;
			height: 40px;
			position: relative;

			img {
				width: 100%;
				height: 100%;
			}

			.status-icon {
				position: absolute;
				right: -6px;
				bottom: 0px;
				width: 16px;
				height: 16px;
			}

			&--empty {
				background-color: $light-background-active;
			}
		}

		&__trailing-icon {
			height: 14px;
		}

		.data-table__multi-line + .data-table__multi-line {
			margin-top: $spacing;
		}

		&__item {
			&-sub-title {
				color: $light-text-secondary;
				font-size: $secondary-text-size;
			}
		}

		tr:last-child() {
			.data-table__data-cell,
			.data-table__options-cell {
				border-bottom: none;
			}
		}
	}

	.data-table--page-part {
		margin-top: $subsection-spacing;
	}

	.icon-placeholder {
		width: 100px;
		margin: 0 auto;

		img {
			width: 100px;
			height: 100px;
			// border-radius: 50%;
		}

		&__caption {
			font-size: $secondary-text-size;
			margin-top: $button-pair-spacing;
			text-align: center;
		}
	}

	.form-field,
	.form-options {
		display: block;
		max-width: 360px;
		margin-bottom: $spacing;
	}

	.form-field {
		font-size: $primary-text-size;
		position: relative;

		&--wide {
			max-width: initial;
			width: 548px;
		}

		&--fluid {
			max-width: 100%;
		}

		&__intro {
			width: 100%;
			color: $light-text;
			margin: 12px 0px;
		}

		&__label {
			font-size: $secondary-text-size;
		}

		&__hint {
			display: inline-block;
			margin-top: 12px;
			font-size: $secondary-text-size;
		}

		&__input,
		&__multi-input,
		&__select-selected,
		&__textarea {
			border: $border-thickness solid $light-border;
			border-radius: $border-radius;
			width: 100%;
			height: $subsection-spacing;
			padding: $input-dropdown-item-padding;

			&:hover {
				border-color: $light-border-hover;
			}

			&:focus,
			&:active {
				border-color: $light-border-focus;
				outline: none;
			}
		}

		&__input,
		&__multi-input,
		&__select-selected {
			line-height: $subsection-spacing;
		}

		&__input {
			&::placeholder {
				font-size: $primary-text-size;
				color: $light-text-secondary;
				line-height: $subsection-spacing;
			}

			&:disabled {
				background-color: $light-background-disabled;
				border-color: transparent;
			}
		}

		&__multi-input {
			height: auto;
			padding: 0;
			position: relative;

			&__selection {
				position: relative;
				padding: 7px 30px 0px 0px;
				border-bottom: $border-thickness solid $light-border;
				display: flex;
				flex-wrap: wrap;
				min-height: 38px;
			}

			&__icon {
				position: absolute;
				right: 10px;
				top: 16px;
				width: 14px;
				cursor: pointer;
			}

			&__placeholder {
				margin: 0px 0px 7px 7px;
				padding: 0px 7px 0px 12px;
				height: 24px;
				font-size: 14px;
				line-height: 24px;
			}

			&__item {
				margin: 0px 0px 7px 7px;
				padding: 0px 7px 0px 12px;
				height: 24px;
				border: $border-thickness solid $light-border;
				border-radius: 4px;
				font-size: 14px;
				line-height: 24px;
				color: $light-text;

				&:hover {
					background: $primary-color--admin;
					color: $light-text-contrast;

					img {
						filter: $filter-white;
					}
				}

				&-button {
					height: auto;
					border: none;
					padding: 0;
					margin: 0;

					img {
						height: 9px;
						width: 9px;
						margin-bottom: 2px;
						margin-left: 8px;
					}

					&:hover {
						background: none;
					}
				}
			}

			&__options {
				padding-top: 7px;
				position: absolute;
				left: -1px;
				top: calc(100% - 1px);
				z-index: 3;
				max-width: 350px;
				max-height: 250px;
				background: $light-background;
				border: $border-thickness solid $light-border;
				border-radius: 0px 0px 4px 4px;
				overflow-y: auto;

				&:hover {
					border-color: $light-border-focus;
				}
			}

			&__option {
				display: block;
				padding: 7px 14px;
				color: $light-text;
				cursor: pointer;

				&:hover {
					background: $light-background-focus;
				}
			}

			&--open {
				border-radius: 4px 4px 0px 0px;
			}
		}

		&__textarea {
			height: 136px;
			padding: 7.5px 14px;

			&:disabled {
				background-color: $light-background-disabled;
				border-color: transparent;
			}
		}

		.sun-editor {
			color: $light-text-secondary;
			font-family: Roboto, sans-serif;
			line-height: 1.5;

			label {
				font-size: $secondary-text-size;
				color: $light-text-secondary;
				margin-bottom: 1px;
			}

			.se {
				&-btn-list {
					border-radius: 0;
					border: none;

					&.active {
						background-color: $light-background-active;
						border: none;
					}
				}

				&-list-layer {
					left: 0 !important;
				}

				&-wrapper {
					height: 400px;
					overflow-y: auto;

					&-inner {
						font-size: $article-text-size;

						h1,
						h2,
						h3,
						h4,
						h5,
						h6,
						.h1,
						.h2,
						.h3,
						.h4,
						.h5,
						.h6 {
							margin: $subsection-spacing 0 $spacing 0;
						}

						h1:first-child {
							margin-top: 0;
						}

						p {
							margin-bottom: $spacing;
						}

						ul,
						ol {
							padding-left: 24px;
						}

						a {
							color: $light-text;
							text-decoration: underline;
						}
					}

					&-code {
						min-height: 400px;
						overflow-y: auto;
					}
				}

				&-dialog {
					&-header {
						border-bottom: none;
						padding: $spacing $spacing 0;
					}

					&-body {
						padding: $spacing;
					}

					&-content {
						top: 50%;
						transform: translate(0, -50%);
						box-shadow: none;

						label {
							font-weight: 400;
							margin-bottom: 1px;
						}
					}

					&-form {
						margin-bottom: 12px;

						input {
							color: $light-text !important;
							padding: 0 8px !important;

							&:focus {
								border-color: $light-border-focus;
								box-shadow: none;
							}
						}
					}

					&-footer {
						padding: 0 $spacing $spacing;
						border-top: none;

						.se-btn-primary {
							margin: 0 !important;
							padding: 0px $spacing;
							height: $subsection-spacing;
							background: none;
							border-radius: 18px;
							color: $light-text-contrast;
							@include button-gradient--admin;
							border: transparent;

							&:hover,
							&:focus {
								background: $secondary-color--admin;
							}
						}
					}

					&-back {
						background: none;
					}

					&-close {
						color: $light-text-secondary;
					}

					&-btn-check {
						width: 18px;
						height: 18px;
					}
				}
			}
		}

		&__select {
			cursor: pointer;
			position: relative;

			&-selected {
				background-image: url("../../svg/select-dropdown-icon.svg");
				background-repeat: no-repeat;
				background-position: right 10px center;
				color: $light-text;
			}

			&-selected--open {
				border-radius: $border-radius $border-radius 0 0;
			}

			&-options {
				position: absolute;
				z-index: 2;
				border: $border-thickness solid $light-border;
				border-radius: 0 0 $border-radius $border-radius;
				border-top: none;
				background-color: $light-background;
				width: 100%;
				top: 36px;
				left: 0px;
				padding-top: 7px;
				padding-bottom: 7px;
				padding-left: 0;
			}

			&-option {
				display: block;
				padding: 7px 14px;
				color: $light-text;

				&:hover {
					background: $light-background-focus;
				}

				&--inactive {
					cursor: default;

					&:hover {
						background: none;
					}
				}
			}
		}

		&__datalist {
			&-clear {
				position: absolute;
				top: 13px;
				right: 13px;
				width: 10px;
				height: 10px;
				background-image: url("../../svg/close.svg");
				filter: $filter-gray;
			}

			&-options {
				border-color: $light-border-focus;
			}
		}

		&__checkbox-container {
			overflow: hidden;
			padding: 0;
			width: 100px;
			background-image: url("../../svg/checkbox-icon.svg");
			background-repeat: no-repeat;
			background-position: left center;
			cursor: pointer;

			&:hover {
				background-image: url("../../svg/checkbox-icon--hover.svg");
			}

			&:focus {
				background-image: url("../../svg/checkbox-icon--focus.svg");
			}

			&--checked,
			&--checked:hover {
				background-image: url("../../svg/checkbox-icon--checked.svg");
			}
		}

		&__checkbox {
			position: relative;
			left: -30px;
			width: 18px;
			margin-right: 9px;
		}

		&__help {
			position: absolute;
			top: 4px;
			right: 0px;

			&::before {
				content: "";
				display: block;
				width: 14px;
				height: 14px;
				background-image: url("../../svg/info.svg");
				background-repeat: no-repeat;
				cursor: pointer;
				filter: $filter-dark-gray;
			}

			&-message {
				display: none;
				position: absolute;
				z-index: 2;
				left: -136px;
				padding: $spacing;
				margin-top: 12px;
				width: 300px;
				color: $light-text;
			}

			&:hover .form-field__help-message {
				display: block;
			}
		}

		&__password {
			position: relative;

			.visibility-icon {
				width: 36px;
				line-height: 36px;
				background-size: 20px;
				background-position: center;
				background-repeat: no-repeat;
				padding: 0;
				border: 0;
				border-radius: 0;
				position: absolute;
				right: 0px;
				cursor: pointer;
			}
		}
	}

	.form-field--dual {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.form-field__intro {
			flex: 0 1 100%;
		}

		.form-field__part {
			width: 170px;
		}
	}

	.form-options {
		text-align: right;

		.button {
			margin-left: $button-pair-spacing;
		}

		.button + .button {
			margin-right: 0px;
		}
	}
}

.admin--dark {
	.form-field {
		font-size: $article-text-size;

		&__intro {
			color: $dark-text;
		}

		&__label {
			color: $dark-text-secondary;
		}

		&__textarea,
		&__input {
			border-color: $dark-border;
			color: $dark-text;
			background-color: transparent;

			&:focus {
				border-color: $dark-border-focus;
			}

			&:hover {
				border-color: $dark-border-hover;
			}
		}
	}
}
