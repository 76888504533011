@import "variables.scss";

.dark-theme {
	min-height: 100vh;
}

.--theme-dark,
.dark-theme {
	// font styling:
	color: $dark-text;

	.body {
		min-height: 100vh;
		width: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		position: relative; // Needed to anchor campaign backgrounds
	}

	.secondary-text {
		font-size: $secondary-text-size;
		color: $dark-text-secondary;
	}

	.text-link {
		color: $dark-text;
		text-decoration: underline;
	}

	.bullet-letter {
		margin-top: $spacing;
		font-weight: bold;
	}

	table {
		width: 100%;
		@include border-dark;
		@include transparent-bg-with-glowbar;

		// Microsoft Edge Only:
		@supports (-ms-ime-align: auto) {
			border: $border-thickness solid $dark-border;
			border-radius: $border-radius;
		}

		tr {
			padding-left: 12px;
			padding-right: 12px;
		}

		th,
		td {
			border-top: $border-thickness solid $dark-border;
		}

		tr:first-child {
			th,
			td {
				border-top: none;
			}
		}

		th {
			padding-top: 15px;
			padding-bottom: 15px;
			font-weight: 400;
			font-size: $secondary-text-size;
			color: $dark-text-secondary;
		}
	}

	button,
	a.button {
		height: $button-height;
		padding: $button-padding;
		border-radius: $button-border-radius;
		border: $border-thickness solid $dark-border;
		background: none;
		color: $dark-text;
		font-size: $primary-text-size;
		text-align: center;

		&:hover {
			border-color: $dark-border-hover;
			cursor: pointer;
		}

		&:focus {
			border-color: $dark-border-focus;
		}

		&:disabled {
			cursor: default;
			background: none;
			color: $dark-text-disabled;
			border-color: $dark-border-disabled;
		}

		&:disabled:hover {
			border-color: $dark-border-disabled;
		}

		.button--active {
			background-color: $dark-background-active;
		}

		&.button--borderless {
			&:hover {
				background: $dark-background-hover;
			}

			&:focus {
				background: $dark-background-focus;
			}

			&:disabled:hover,
			&:disabled:focus {
				background: none;
			}
		}
	}

	.button-secondary {
		color: $dark-text-secondary;
	}

	.button-blue {
		@include button-gradient;
		border: none;
		padding: $button-no-border-padding;
		color: $dark-text;

		&:hover,
		&:focus,
		&:active {
			background: $secondary-color;
		}

		&:hover {
			cursor: pointer;
		}

		&:disabled {
			cursor: default;
			background: none;
			border: $border-thickness solid $dark-border;
			color: $dark-border-focus;
			padding: $button-padding;
		}
	}

	.button-big {
		height: $button-big-height;
		padding: $button-big-padding;
		border-radius: $button-border-radius;
	}

	.button-big.button-blue {
		padding: $button-big-no-border-padding;
	}

	.TextField,
	.TextareaField,
	.SelectField {
		color: $dark-text-secondary;

		&:hover {
			.field {
				border-color: $dark-border-hover;
			}
		}

		.label {
			margin-bottom: 2px;
			font-size: $secondary-text-size;
			color: $dark-text-secondary;
		}

		.field {
			border: $border-thickness solid $dark-border;
			color: $dark-text-secondary;

			&:focus,
			&:active {
				outline: none;
				color: $dark-text;
			}

			&:focus {
				box-shadow: none;
				border-color: $dark-text-secondary;
			}

			&::placeholder {
				font-size: $primary-text-size !important;
			}
		}
	}

	.SelectField {
		.react-dropdown-select {
			caret-color: $dark-text;

			&-input {
				color: $dark-text;
			}

			&-dropdown {
				background: $dark-modal-background-color;
				border: $border-thickness solid $dark-border;
				color: $dark-text;

				&-handle {
					svg {
						filter: $filter-dark-dropdown-arrow-color;
					}
				}
			}

			&-item {
				&-selected {
					background-color: $dark-background-focus !important;
				}

				&:hover,
				&:active,
				&:focus,
				&:focus-within,
				&::selection {
					background-color: $dark-background-hover;
				}
			}

			.react-dropdown-select-clear {
				filter: $filter-white;
			}

			&:hover {
				.react-dropdown-select-dropdown {
					border-color: $dark-border-hover;
				}
			}

			&:focus {
				.react-dropdown-select-dropdown {
					border-color: $dark-border-focus;
				}
			}
		}
	}

	.modal-content {
		background-color: $dark-modal-background-color;
		border: $border-thickness solid $dark-border;
	}

	.ellipsis-vertical {
		filter: $filter-dark-text-secondary;
	}

	.dropdown-menu {
		background-color: $dark-modal-background-color;

		button {
			color: $dark-text;
		}
	}

	.PreferredGames {
		.game-button {
			border-color: $dark-border;

			&:active,
			&:hover,
			&:focus {
				background: none;
			}

			&:hover {
				border-color: $dark-border-focus;
			}

			&:focus {
				border-color: $dark-text-secondary;
			}
		}
	}
}

.modal.fade .dark-theme.modal-dialog {
	transition: -webkit-transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
	-webkit-transform: matrix(0.95, 0, 0, 0.95, 0, 6);
	transform: matrix(0.95, 0, 0, 0.95, 0, 6);
}

.modal.show .dark-theme.modal-dialog {
	-webkit-transform: matrix(1, 0, 0, 1, 0, 0);
	transform: matrix(1, 0, 0, 1, 0, 0);
}

.video-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
